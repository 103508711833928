import { FC, ReactElement, ReactNode } from 'react'

import TippyBase, { TippyProps } from '@tippyjs/react'
import styled from 'styled-components'
import { Placement } from 'tippy.js'

import Icon from 'core/components/Icon'
import variables from 'core/styles/variables'

import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'
import 'tippy.js/animations/shift-away.css'

type TooltipProps = {
  content: ReactNode
  children?: ReactElement<any> // Tippy has an incorrectly typed 'children' prop
  animation?: string
  appendTo?: any
  arrow?: boolean
  backgroundColor?: string
  color?: string
  disabled?: boolean
  hideOnClick?: boolean
  placement?: Placement
  width?: number
  delay?: number | [number, number]
  trigger?: string
}

type TooltipIconProps = {
  iconMargin?: string
  iconSize?: string
  inline?: boolean
}

const Tooltip: FC<TooltipProps & TooltipIconProps> = ({
  animation,
  appendTo = 'parent',
  arrow = true,
  backgroundColor = 'white',
  children,
  color = variables.colorBlack70,
  content,
  disabled = false,
  hideOnClick = true,
  iconMargin = '0',
  iconSize = '24px',
  placement = 'bottom-end',
  width = 648,
  inline,
  delay,
  trigger = 'mouseenter click',
}) => {
  const offset: [number, number] =
    placement === 'bottom-end' ? [32, 8]
    : placement === 'bottom-start' ? [-32, 8]
    : placement === 'top' ? [0, 12]
    : [0, 0]

  return (
    <Tippy
      data-testid='tippy-tooltip'
      appendTo={appendTo}
      arrow={arrow}
      animation={animation}
      backgroundColor={backgroundColor}
      color={color}
      content={content}
      hideOnClick={hideOnClick}
      interactive
      maxWidth={width}
      offset={offset}
      placement={placement}
      theme='light'
      trigger={trigger}
      disabled={disabled}
      delay={delay}
    >
      <IconWrapper margin={iconMargin}>
        {children ?? <Icon name='help' outlined fontSize={iconSize} color={variables.colorBlack60} inline={inline} />}
      </IconWrapper>
    </Tippy>
  )
}

export default Tooltip

/**
 * If you attempt to pass props into Tippy that are not part of their
 * documentation, it will output a console warning in dev mode. This
 * helps us remove the prop that Tippy doesn't allow which will consequently
 * remove the console warnings in the browser. For now Tippy doesn't
 * allow the props 'backgroundColor', 'color', and 'fontSize'. If you
 * want to use additional props on the Tippy styled component that are
 * not part of their doc, simply add those in the object destructring
 * of the props of PropsOmittedTippyBase.
 */
const PropsOmittedTippyBase: FC<TooltipProps & TippyProps & { 'data-testid'?: string }> = ({
  backgroundColor,
  color,
  'data-testid': dataTestid,
  ...rest
}) => <TippyBase {...rest} />

export const Tippy = styled(PropsOmittedTippyBase)`
  border-radius: 8px;
  box-shadow: ${variables.peachyShadow};
  background-color: ${(p) => p.backgroundColor} !important;
  padding: 8px;
  font-size: 16px;

  > .tippy-arrow {
    color: ${(p) => p.backgroundColor};
  }

  ${(p) => (p.color ? `color: ${p.color};` : '')}
`
export const IconWrapper = styled.span.attrs(() => ({
  role: 'button',
}))<{ margin?: string }>`
  display: inline-flex;
  align-items: baseline;
  margin: ${(p) => p.margin};
  cursor: pointer;
`
