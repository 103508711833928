import { FC, ReactChild } from 'react'

import * as ToastPrimitive from '@radix-ui/react-toast'
import styled, { keyframes } from 'styled-components'

import { IconButton, errorBannerCss, successBannerCss, warningBannerCss } from 'core/components'
import { variables } from 'core/styles'

export type ToastData = {
  id: string
  message: ReactChild
  type: 'error' | 'warning' | 'success'
}

type ToastProps = ToastData & {
  onOpenChange?: (value: boolean) => void
}

const Toast: FC<ToastProps> = ({ message, type, onOpenChange }) => (
  <ToastPrimitive.Root onOpenChange={onOpenChange} asChild>
    <StyledToast $type={type}>
      <StyledDescription>{message}</StyledDescription>
      <ToastPrimitive.Close asChild>
        <IconButton name='clear' ariaLabel='Dismiss' />
      </ToastPrimitive.Close>
    </StyledToast>
  </ToastPrimitive.Root>
)

export default Toast

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`

const slideIn = keyframes`
  from {
    transform: translateX(calc(100% + 16px));
  }
  to {
    transform: translateX(0);
  }
`

const StyledToast = styled.li<{ $type: ToastProps['type'] }>`
  ${(p) => p.$type === 'error' && errorBannerCss};
  ${(p) => p.$type === 'warning' && warningBannerCss};
  ${(p) => p.$type === 'success' && successBannerCss};
  display: flex;
  align-items: flex-start;
  border-radius: 8px;
  box-shadow:
    ${variables.peachyShadowSmall},
    inset 0 0 3px 0
      ${(p) =>
        p.$type === 'error' ? variables.colorRed
        : p.$type === 'warning' ? variables.colorOrange
        : ''};
  padding: 16px;
  max-width: 480px;
  color: ${variables.colorBlack100};

  & + & {
    margin-top: 12px;
  }

  /* open and close animation */
  &[data-state='open'] {
    animation: ${slideIn} 150ms cubic-bezier(0.16, 1, 0.3, 1);
  }

  &[data-state='closed'] {
    animation: ${fadeOut} 100ms ease-in;
  }

  /* swipe gesture animation */
  &[data-swipe='cancel'] {
    transform: translateX(0);
    transition: transform 200ms ease-out;
  }
`

const StyledDescription = styled(ToastPrimitive.Description)`
  flex-grow: 1;
`
