import {
  FC,
  MouseEventHandler,
  PropsWithChildren,
  ReactChild,
  RefObject,
  useEffect,
  ChangeEvent as ReactChangeEvent,
} from 'react'

import { ensuredForwardRef } from 'react-use'
import styled from 'styled-components'

import { PendingEventHandler, usePendingCallback } from 'core/components/lib/Button/Button'
import Spinner from 'core/components/lib/Spinner'
import variables from 'core/styles/variables'

import { useFormDisabled } from 'loan/components/actionCards/shared/DisabledContext'

import { CheckboxInput, CheckboxLabel, InnerContainer, OuterContainer } from './CheckboxStyles'

export const DisabledReason = styled.div`
  padding: 8px 0 0 32px;
  color: ${variables.colorBlack60};
  font-size: 14px;
  font-weight: 300;
`

export type CheckboxProps = PropsWithChildren<{
  checked?: boolean
  defaultChecked?: boolean
  color?: string
  disabled?: boolean
  disabledReason?: string | null
  flexReverse?: boolean
  fontSize?: string
  indeterminate?: boolean
  label?: ReactChild
  ariaLabel?: string
  labelPosition?: 'top'
  loading?: boolean
  margin?: string
  onChange?: PendingEventHandler<ReactChangeEvent<HTMLInputElement>>
  onClick?: MouseEventHandler<HTMLInputElement>
  padding?: string
  value: string
}>

const Checkbox: FC<CheckboxProps> = ensuredForwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      checked,
      defaultChecked,
      children,
      color,
      disabled,
      disabledReason,
      flexReverse,
      fontSize,
      indeterminate = false, // used for parent checkboxes with only some children checked
      label,
      ariaLabel,
      labelPosition,
      loading,
      margin = '0',
      onChange: _onChange,
      onClick,
      padding,
      value,
    },
    ref,
  ) => {
    const [isPending, onChange] = usePendingCallback(_onChange)

    const isDisabled = useFormDisabled(disabled)

    useEffect(() => {
      // ensuredForwardRef guarantees ref will be a RefObject but is typed wrong
      const current = (ref as RefObject<HTMLInputElement | null>)?.current
      if (current) {
        current.indeterminate = indeterminate
      }
    }, [ref, indeterminate])

    return (
      <OuterContainer margin={margin}>
        <InnerContainer className='checkbox' flexReverse={flexReverse} labelPosition={labelPosition}>
          <>
            {loading || isPending ?
              <Spinner />
            : <CheckboxInput
                checked={checked}
                defaultChecked={defaultChecked}
                disabled={isDisabled}
                ref={ref}
                id={value}
                name={value}
                onChange={onChange}
                onClick={onClick}
                type='checkbox'
                value={value}
                aria-label={ariaLabel}
              />
            }
          </>
          <CheckboxLabel
            color={color}
            flexReverse={flexReverse}
            fontSize={fontSize}
            htmlFor={value}
            labelPosition={labelPosition}
            padding={padding}
          >
            {label}
          </CheckboxLabel>
          {disabled && disabledReason && <DisabledReason>{disabledReason}</DisabledReason>}
          {children}
        </InnerContainer>
      </OuterContainer>
    )
  },
)

export default Checkbox
