import { ReactNode, useEffect } from 'react'

import ReactSelect, {
  GroupBase,
  MultiValue,
  OnChangeValue,
  PropsValue,
  Props as ReactSelectProps,
  SingleValue,
} from 'react-select'
import * as R from 'remeda'
import styled from 'styled-components'

import customStyles from 'core/components/legacy/Select/customStyles'
import { PendingEventHandler, usePendingCallback } from 'core/hooks'

import { DropdownIndicator, MenuList, Option, OptionsOrGroups, SelectOption, ValueContainer } from './components'

export const findByValue = <T,>(options: OptionsOrGroups<T> | undefined, value: T) =>
  R.pipe(
    options ?? [],
    R.flatMap((o) => ('options' in o ? o.options : o)),
    R.find((o) => R.equals(o.value, value)),
  )

export type SelectProps<IsMulti extends boolean, Value> = Omit<
  ReactSelectProps<SelectOption<Value>, IsMulti, GroupBase<SelectOption<Value>>>,
  'value' | 'onChange' | 'isDisabled' | 'onBlur' | 'styles' | 'defaultValue'
> & {
  value: PropsValue<Value> | null
  onChange?: PendingEventHandler<OnChangeValue<Value, IsMulti> | null>
  disabled?: boolean
  className?: string
  width?: string
  prefix?: ReactNode
}

const Select = <IsMulti extends boolean = false, Value = string>({
  name,
  value,
  disabled,
  onChange: rawOnChange,
  className,
  width,
  options,
  isMulti,
  isLoading,
  ...reactSelectProps
}: SelectProps<IsMulti, Value>) => {
  const selected =
    R.isNil(value) ? value
    : isMulti ?
      R.pipe(
        value as Array<Value>,
        R.map((v) => findByValue(options, v)),
        R.compact,
      )
    : findByValue<Value>(options, value as Value)

  useEffect(() => {
    if (!isLoading && !R.isNil(value) && selected === undefined) {
      rawOnChange?.(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  const checkMulti = (
    option: MultiValue<SelectOption<Value>> | SingleValue<SelectOption<Value>>,
  ): option is MultiValue<SelectOption<Value>> => !!isMulti

  const [isPending, onChange] = usePendingCallback((option: OnChangeValue<SelectOption<Value>, IsMulti>) => {
    rawOnChange?.(
      (!option ? option
      : checkMulti(option) ? R.map(option, R.prop('value'))
      : option.value) as OnChangeValue<Value, IsMulti>,
    )
  })

  return (
    <Box className={className} width={width}>
      <ReactSelect
        components={{
          DropdownIndicator,
          MenuList,
          Option,
          ValueContainer,
        }}
        styles={customStyles}
        options={options}
        isDisabled={isPending || isLoading || disabled}
        onChange={onChange}
        menuPlacement='auto'
        isLoading={isPending || isLoading}
        value={selected} // Workaround for JedWatson/react-select#5729
        {...reactSelectProps}
        {...(isMulti && { isMulti })}
      />
    </Box>
  )
}

export default Select

const Box = styled.div<{ width?: string }>`
  width: ${(p) => p.width ?? 'initial'};
`
