import React, { useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Spinner } from 'core/components'
import Icon from 'core/components/Icon'
import variables from 'core/styles/variables'

const Status = styled.div`
  margin-top: ${(p) => p.marginTop};
  margin-bottom: ${(p) => p.marginBottom};
  text-align: center;
  font-size: 12px;
  font-weight: 400;
`

const Label = styled.span`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
`

const LabelText = styled.span`
  display: inline-block;
  margin-right: 8px;
  color: ${variables.colorBlack50};
`

const CodeSentStatus = (props) => {
  const { sending, label, marginTop, marginBottom } = props
  const [visible, setVisible] = useState(true)

  useEffect(() => {
    if (!sending) {
      setTimeout(() => setVisible(false), 6000)
    } else {
      setVisible(true)
    }
  }, [sending])

  const contents =
    sending ?
      <Spinner />
    : <Label>
        <LabelText>{label || 'Code sent'}</LabelText>
        <Icon name='check' color={variables.colorGreen} inline />
      </Label>

  return visible && (sending || label) ?
      <Status marginTop={marginTop} marginBottom={marginBottom}>
        {contents}
      </Status>
    : null
}

CodeSentStatus.propTypes = {
  sending: PropTypes.bool,
  label: PropTypes.node,
  marginTop: PropTypes.string,
  marginBottom: PropTypes.string,
}

export default CodeSentStatus
