import React from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import { CompanyLogo } from 'core/components'
import Icon from 'core/components/Icon'
import { useRect } from 'core/hooks'
import variables from 'core/styles/variables'
// this should be portal-ing
// so it doesn't pick up styles from it's react parent

const Wrapper = styled.div`
  position: fixed;
  inset: 0;
  margin: 0 !important;
  background-color: rgb(0 0 0 / 50%);
  padding: 120px 16px;
  overflow-y: auto;
`

const Card = styled.div`
  margin: auto;
  border-radius: 6px;
  box-shadow: ${variables.peachyShadow};
  background: ${variables.colorWhite};
  max-width: 480px;
  overflow: hidden;
`

const CardContents = styled.div`
  padding: ${(p) => (p.isCompact ? '16px' : '28px 40px')};
`

const LogoArea = styled.div`
  text-align: center;
`

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const CloseButton = styled.button`
  display: inline-block;
  position: absolute;
  top: 12px;
  right: 12px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 4px 12px;
  color: white;
`

const ErrorFooter = styled.div`
  background-color: ${variables.colorRedLighten};
  padding: 8px 24px;
  color: ${variables.colorBlack80};
  font-size: 14px;
`

const OneTimeCodeOverlay = ({ children, errorMessage, onClose }) => {
  const [{ width }, ref] = useRect()

  const isCompact = width < 420

  return (
    <Wrapper>
      <Card ref={ref} isCompact={isCompact}>
        <CardContents>
          <LogoArea>
            <CompanyLogo type='appLargeCentered' />
          </LogoArea>

          <Contents>{children}</Contents>
        </CardContents>

        {errorMessage && <ErrorFooter>{errorMessage}</ErrorFooter>}
      </Card>
      <CloseButton onClick={onClose}>
        <Icon name='clear' inline />
      </CloseButton>
    </Wrapper>
  )
}

OneTimeCodeOverlay.propTypes = {
  errorMessage: PropTypes.string,
  children: PropTypes.node,
  onClose: PropTypes.func,
}

export default OneTimeCodeOverlay
