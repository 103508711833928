import styled from 'styled-components'

import { variables } from 'core/styles'

export const ErrorMessage = styled.p<{ color?: string; align?: string }>`
  margin: 8px 0;
  text-align: ${(props) => props.align ?? 'left'};
  color: ${(props) => props.color ?? variables.colorRed};
  font-size: 14px;
`

export default ErrorMessage
