import _ from 'lodash'

const pascalCaseify = (str) => {
  if (!str || !_.isString(str)) return ''
  return str.slice(0, 1).toUpperCase() + str.slice(1)
}

const humanizeSnakeString = (str) => {
  return str
    .replace(/_/g, ' ') // underscores -> spaces
    .replace(/\b\w/g, (word) => word.toUpperCase()) // capitalize words
    .trim()
}

const humanizeCamelString = (str) => {
  return pascalCaseify(str)
    .replace(/([A-Z]+)/g, '$1')
    .replace(/([A-Z][a-z])/g, ' $1')
    .trim()
}

const acronyms = ['id', 'ui', 'gui', 'saml']

const humanizeKey = (key = '') => {
  if (_.includes(acronyms, key)) return key.toUpperCase()

  const str = _.includes(key, '_') ? humanizeSnakeString(key) : humanizeCamelString(key)

  return str.endsWith(' Id') ? str.slice(0, -3) + ' ID' : str
}

export default _.memoize(humanizeKey)
