import { useState, useMemo, Dispatch, SetStateAction } from 'react'

import _ from 'lodash'

import useOnChangeEffect from '../useOnChangeEffect'

import { store, retrieve } from './storage'
import { combine } from './storedStateHelpers'
import { useStoredStateNamespace } from './StoredStateNamespace'

// this hook can be used a useState() call, but will be localStorage-backed

function useStoredState<T>(passedKey: string, defaultValue?: T): [T, Dispatch<SetStateAction<T>>] {
  const parentKey = useStoredStateNamespace()

  const fullKey = combine(parentKey, passedKey)

  const [value, setValue] = useState<T>(() => retrieve(fullKey) ?? defaultValue)

  const onChange = useMemo(() => {
    return (newValue: SetStateAction<T>) => {
      if (!passedKey) return
      setValue((value) => {
        const result = _.isFunction(newValue) ? newValue(value) : newValue
        store(fullKey, result)
        return result
      })
    }
  }, [passedKey, fullKey])

  // if the key changes during use, reload the data
  useOnChangeEffect(() => setValue(retrieve(fullKey)), [fullKey])

  return [value, onChange]
}

export default useStoredState
