import React from 'react'

import _ from 'lodash'
import PropTypes from 'prop-types'
import ContentLoader from 'react-content-loader'
import styled from 'styled-components'

import { useRect } from 'core/hooks'

import useSkeletonRects from './useSkeletonRects'

// this skeleton should get its size from it's content
// without any scaling artifacts
// similar to any other regular DOM node

/* syntax: 

- spaces don't matter
- newline indicates new entry 
- comma indicates new inline entry 

properties: 

- width - number of pixels  (can also be '100%')
- height - number of pixels
- gap (optional, number of pixels, defaults to 8)

regular skeleton item: 

  [W] x [H] --gap=[BELOW]
  [W] x [H] --gap=[BELOW]

produces: 

  [========]
  [========]
  
inline skeleton items: 

  [BIG W] x [H] --gap=[BELOW]
  [W] x [H] --gap=[RIGHT], [W] x [H] --gap=[RIGHT], [W] x [H] --gap=[BELOW]
  [BIG W] x [H] --gap=[BELOW]
  [W] x [BIG H] --gap=[BELOW]

produces: 

  [=============]
  [========]  [========]  [========]
  [=============]

  [--------]
  [        ]
  [--------]
  
*/

const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  padding: ${(p) => p.padding ?? '0'};
  max-width: ${(p) => (p.maxWidth ? `${p.maxWidth}px` : 'none')};
`

const Skeleton = ({ children, maxWidth, speed, padding }) => {
  const [{ width: containerWidth }, ref] = useRect()

  const displayWidth = _.min([maxWidth, containerWidth])

  const { rects, y: contentheight } = useSkeletonRects(children, containerWidth)

  return (
    <Wrapper ref={ref} maxWidth={maxWidth} padding={padding}>
      <ContentLoader
        height={contentheight}
        width={displayWidth}
        speed={speed ?? 5}
        backgroundColor='#f3f3f3'
        foregroundColor='#ecebeb'
      >
        {rects}
      </ContentLoader>
    </Wrapper>
  )
}

Skeleton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  speed: PropTypes.number,
  padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default Skeleton
