import './index.scss'

import { forwardRef, MouseEventHandler, MouseEvent as ReactMouseEvent, PropsWithChildren, useState } from 'react'

import classNames from 'classnames'
import _ from 'lodash'
import styled from 'styled-components'

import variables from 'core/styles/variables'

import { useFormDisabled } from 'loan/components/actionCards/shared/DisabledContext'

import Spinner, { SpinnerContainer } from '../Spinner/Spinner'

// TODO: Component is too complex; should be refactored to be composable:
// * Error rendering should be external to rendering buttons
// * Should not have/require any knowledge of a form context
// * Should extend expected HTML button API (readOnly => disabled)
// * Base <Button /> component with the shared styles for all buttons
// * Variations should be handled as discrete components instead of with props
// * <ButtonRounded />, <PrimaryButton />, <PrimaryButtonRounded />, etc

export const StyledButton = styled.button<{
  inline?: boolean
  margin?: string
  padding?: string
  fontSize?: string
  primary?: boolean
  backgroundColor?: string
  errorMessage?: boolean
}>`
  ${(p) => p.inline && 'display: inline-flex;'}
  margin: ${(p) => p.margin ?? '0'};
  padding: ${(p) => p.padding ?? '0'};
  font-size: ${(p) => p.fontSize ?? '16px'};
  ${(p) => p.primary && `background-color: ${p.theme.primary};`}
  ${(p) => p.backgroundColor && `background-color: ${p.backgroundColor};`}
  ${(p) => p.color && `color: ${p.color};`}
  ${(p) =>
    p.errorMessage &&
    `
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-style: none;
  `}

  > ${SpinnerContainer} {
    margin-left: 8px;
  }
`

export const ErrorMessage = styled.div`
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: ${variables.colorRedLighten};
  padding: 8px 24px;
  color: ${variables.colorBlack80};
`

const maxErrorLength = 400

export const usePendingCallback = <T extends Array<unknown>, R extends Promise<unknown> | unknown>(
  cb?: (...args: T) => R,
) => {
  const [isPending, setIsPending] = useState(false)

  return [
    isPending,
    (...args: T) => {
      const res = cb?.(...args)

      if (res instanceof Promise) {
        setIsPending(true)
        res.finally(() => setIsPending(false)).catch(_.noop)
      }

      return res
    },
  ] as const
}

export type PendingEventHandler<T = ReactMouseEvent<HTMLButtonElement, MouseEvent>> = (
  event: T,
) => Promise<unknown> | void

export type ButtonProps = PropsWithChildren<{
  onClick?: PendingEventHandler
  isLoading?: boolean
  color?: string
  focus?: boolean
  borderType?: 'solid-light' | 'dashed-light' | 'none'
  primary?: boolean
  primaryOutline?: boolean
  secondary?: boolean
  primaryLight?: boolean
  fullWidth?: boolean
  inline?: boolean
  readOnly?: boolean
  margin?: string
  padding?: string
  className?: string
  fontSize?: string
  errorMessage?: string
  type?: 'button' | 'reset' | 'submit'
  title?: string
  backgroundColor?: string
  onMouseEnter?: MouseEventHandler
  onMouseLeave?: MouseEventHandler
}>

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      onClick: _onClick,
      isLoading,
      children,
      focus = false,
      borderType = 'none',
      primary,
      primaryLight,
      primaryOutline,
      secondary,
      fullWidth,
      className,
      readOnly: passedReadOnly,
      errorMessage,
      type = 'button',
      ...restProps
    },
    ref,
  ) => {
    const [isPending, onClick] = usePendingCallback(_onClick)

    const croppedErrorMsg =
      errorMessage && errorMessage.length > maxErrorLength ?
        `${errorMessage?.substring(0, maxErrorLength)}...`
      : errorMessage

    const readOnly = useFormDisabled(passedReadOnly)

    // TODO: refactor this with style-component's pattern
    const componentClass = classNames(className, 'button', {
      focus: focus,
      [`border-${borderType}`]: [`border-${borderType}`],
      primary: primary,
      'primary-light': primaryLight,
      'primary-outline': primaryOutline,
      secondary: secondary,
      'full-width': fullWidth,
      disabled: readOnly || isPending || isLoading,
    })

    return (
      <>
        <StyledButton
          {...restProps}
          ref={ref}
          type={type}
          className={componentClass}
          onClick={onClick}
          disabled={readOnly || isPending || isLoading}
          primary={primary}
          errorMessage={!!errorMessage}
        >
          {children}
          {(isPending || isLoading) && <Spinner width='24px' />}
        </StyledButton>
        {errorMessage && <ErrorMessage>{croppedErrorMsg}</ErrorMessage>}
      </>
    )
  },
)

export default Button
