import { FC, PropsWithChildren, useState } from 'react'

import { Close } from '@radix-ui/react-dialog'

import Button from 'core/components/lib/Button'
import { PendingEventHandler } from 'core/components/lib/Button/Button'
import Dialog, { DialogActions, DialogText } from 'core/components/lib/Dialog/Dialog'

type ConfirmDialogProps = PropsWithChildren<{
  title: string
  content?: string
  onConfirm: PendingEventHandler<React.MouseEvent<HTMLButtonElement, MouseEvent>>
  isOpen?: boolean
  onOpenChange?: (value: boolean) => void
}>

const ConfirmDialog: FC<ConfirmDialogProps> = ({ children, title, content, onConfirm, isOpen, onOpenChange }) => {
  const [isOpenLocal, setOpenLocal] = useState(false)

  return (
    <Dialog
      isOpen={isOpen || isOpenLocal}
      onOpenChange={onOpenChange ?? setOpenLocal}
      title={title}
      content={
        <>
          {content && <DialogText>{content}</DialogText>}
          <DialogActions>
            <Close asChild>
              <Button>No</Button>
            </Close>
            <Button
              secondary
              onClick={async (e) => {
                await onConfirm(e)

                if (onOpenChange) {
                  onOpenChange(false)
                } else {
                  setOpenLocal(false)
                }
              }}
            >
              Yes
            </Button>
          </DialogActions>
        </>
      }
    >
      {children}
    </Dialog>
  )
}

export default ConfirmDialog
