import React from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import Button from 'core/components/lib/Button'
import Spinner from 'core/components/lib/Spinner'
import variables from 'core/styles/variables'

const Position = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 24px;

  svg {
    position: absolute;
  }
`
const ButtonGroup = styled.div`
  display: flex;

  .button:first-child:not(:only-child) {
    margin-right: 32px;
  }

  .button:last-child:not(:only-child) {
    min-width: ${(props) => (props.hasStatusIcon ? '112px' : 'auto')};
    white-space: nowrap;
  }
`
const SpinnerContainer = styled.div`
  margin: 0 0 0 4px;
  width: 18px;
  height: 18px;
`

const ActionButons = ({
  mainActionLabel,
  secondaryActionLabel,
  successActionLabel,
  onMainAction,
  onSecondaryAction,
  onSuccessAction,
  hasStatusIcon,
  loading,
  success,
  readOnly,
  disableMainAction,
}) => {
  const onClickMainButton = () => {
    if (success) {
      if (onSuccessAction) {
        onSuccessAction()
      } else {
        onMainAction()
      }
    } else {
      onMainAction()
    }
  }

  return (
    <Position>
      <ButtonGroup hasStatusIcon={hasStatusIcon}>
        {secondaryActionLabel && onSecondaryAction && (
          <Button onClick={onSecondaryAction} readOnly={readOnly} className='button-secondary-action'>
            {secondaryActionLabel}
          </Button>
        )}
        <Button
          secondary
          onClick={onClickMainButton}
          readOnly={readOnly || disableMainAction}
          color={success ? variables.colorBluePrimary : ''}
          id='button-main-action'
        >
          {success ? successActionLabel || mainActionLabel : mainActionLabel}
        </Button>
      </ButtonGroup>
      {loading && (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      )}
    </Position>
  )
}

ActionButons.propTypes = {
  mainActionLabel: PropTypes.string.isRequired,
  secondaryActionLabel: PropTypes.string,
  successActionLabel: PropTypes.string,
  onMainAction: PropTypes.func.isRequired,
  onSecondaryAction: PropTypes.func,
  onSuccessAction: PropTypes.func,
  hasStatusIcon: PropTypes.bool,
  loading: PropTypes.bool,
  success: PropTypes.bool,
  readOnly: PropTypes.bool,
  disableMainAction: PropTypes.bool,
}

export default ActionButons
