import { ReactChild } from 'react'

import { Root, Indicator } from '@radix-ui/react-checkbox'
import styled from 'styled-components'

import Spinner from 'core/components/lib/Spinner'
import { PendingEventHandler, usePendingCallback } from 'core/hooks'
import { Field } from 'core/hooks/useForm'
import variables from 'core/styles/variables'

type CheckboxProps<T extends boolean | 'indeterminate'> = Field<T> & {
  onChange?: PendingEventHandler<T>
  className?: string
  label?: ReactChild
  ariaLabel?: string
  isLoading?: boolean
}

const Checkbox = <T extends boolean | 'indeterminate'>({
  value,
  name,
  disabled,
  onChange: _onChange,
  label,
  ariaLabel,
  isLoading,
  className,
}: CheckboxProps<T>) => {
  const [isPending, onChange] = usePendingCallback(_onChange)

  return (
    <Box $disabled={disabled} className={className}>
      {isPending || isLoading ?
        <Spinner width='20px' height='20px' />
      : <StyledRoot
          checked={value}
          value={value.toString()}
          onCheckedChange={onChange}
          disabled={disabled}
          name={name}
          id={name}
          aria-label={ariaLabel}
        >
          <StyledIndicator forceMount />
        </StyledRoot>
      }
      {label && <label htmlFor={name}>{label}</label>}
    </Box>
  )
}

export default Checkbox

const Box = styled.div<{ $disabled: boolean | undefined }>`
  display: flex;
  gap: 8px;
  align-items: center;

  button,
  label {
    cursor: ${(p) => (p.$disabled ? 'default' : 'pointer')};
  }
`

const StyledRoot = styled(Root)`
  box-sizing: border-box;
  position: relative;
  border: 2px solid ${variables.colorBlack70};
  border-radius: 10%;
  background-color: ${variables.colorWhite};
  width: 20px;
  height: 20px;

  &[data-state='checked'] {
    border-color: ${(p) => p.theme.primary};
    background-color: ${(p) => p.theme.secondary};
  }

  &[data-disabled] {
    border-color: ${variables.colorBlack50};
    background-color: ${variables.colorBlack50};
  }
`

const StyledIndicator = styled(Indicator)`
  box-sizing: border-box;
  position: absolute;
  right: 9.5px;
  bottom: 4px;
  transform: rotate(45deg);
  transform-origin: bottom right;
  opacity: 0;
  border-right: 2px solid white;
  border-bottom: 2px solid white;
  border-radius: 10%;
  width: 0;
  height: 0;

  &[data-state='checked'] {
    transition: all 0.2s ease-in;
    opacity: 1;
    width: 6px;
    height: 10px;
  }

  &[data-state='indeterminate'] {
    right: 3px;
    bottom: 7px;
    transform: none;
    transition: all 0.2s ease-in;
    opacity: 1;
    border-color: ${variables.colorBlack70};
    width: 10px;
    height: 0;

    &[data-disabled] {
      border-color: white;
    }
  }
`
