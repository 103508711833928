const white = '#FFFFFF'
const grey50 = '#FAFAFA'
const grey100 = '#F2F2F2'
// const grey200 = '#E3E3E3'
// const grey300 = '#D3D3D3'
const grey400 = '#BDBDBD'
// const grey500 = '#88888B'
// const grey600 = '#6F6F72'
// const grey700 = '#565659'
// const grey800 = '#3E3E40'
const grey900 = '#252528'

// const blue50 = '#DFF2FF'
// const blue100 = '#B2D6FF'
// const blue200 = '#84BBFA'
// const blue300 = '#55A0F5'
const blue400 = '#2885F1'
// const blue500 = '#0E6BD7'

const green500 = '#28BD8C'

const red400 = '#F1273C'

// Aliases
export const primary = blue400
export const onPrimary = white
export const success = green500
export const onSuccess = white
export const error = red400
export const onError = white
// export const outline = grey200

export const surface = grey50
export const onSurface = grey900
export const surfaceDisabled = grey100
export const onSurfaceDisabled = grey400
