import { forwardRef, useState } from 'react'

import ReactInputMask from 'react-input-mask'
import styled from 'styled-components'

import Radio from 'core/components/legacy/Radio'
import DateInput, { DateInputProps } from 'core/components/lib/DateInput/DateInput'

type DateInputWithPresetsProps = DateInputProps & {
  presets: Array<{
    label: string
    value: string | null
  }>
}

const DateInputWithPresets = forwardRef<ReactInputMask, DateInputWithPresetsProps>(
  ({ disabled, presets, onBlur, onChange, value, ...dateInputProps }, ref) => {
    const [selectedPreset, setSelectedPreset] = useState<string | null>(presets[0].value)
    return (
      <>
        {presets.map((preset) => (
          <RadioWrapper key={preset.value}>
            <Radio
              disabled={disabled}
              label={preset.label}
              checked={preset.value === selectedPreset}
              onChange={() => {
                setSelectedPreset(preset.value)
                onChange?.(preset.value)
              }}
              value={preset.value ?? 'null'}
              id={preset.value ?? 'null'}
            />
          </RadioWrapper>
        ))}
        <Radio
          checked={selectedPreset === 'custom'}
          disabled={disabled}
          onChange={() => setSelectedPreset('custom')}
          id='custom'
          width='100%'
        >
          <DateInput
            ref={ref}
            {...dateInputProps}
            disabled={disabled}
            onBlur={(e) => {
              // reset to the preset value if the user: entered something that matches a preset, or entered nothing
              const matchedPreset = presets.find((preset) => preset.value === value)
              if (matchedPreset) setSelectedPreset(matchedPreset.value)
              onBlur?.(e)
            }}
            onChange={onChange}
            onFocus={() => setSelectedPreset('custom')}
            value={value}
          />
        </Radio>
      </>
    )
  },
)

export default DateInputWithPresets

const RadioWrapper = styled.div`
  margin-bottom: 16px;
`
