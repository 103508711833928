import { isNil, isObject, keys, omitBy, purry, reduce } from 'remeda'

export * from 'remeda'

export const isEmptyObject = (obj: any) => isObject(obj) && keys(obj).length === 0

export const omitNil = <T>(obj: T) =>
  omitBy(obj, isNil) as {
    [P in keyof T]?: Exclude<T[P], null>
  }

/**
 * A function that checks if the given property of the passed parameter is defined and narrows its type accordingly.
 * @param propName the property name
 * @signature R.hasDefined(prop)(object)
 * @example
 *    R.pipe({foo: 'bar'}, R.hasDefined('foo')) // => true
 * @dataLast
 * @category Guard
 */
export const hasDefined =
  <T, K extends keyof T = keyof T>(propName: K) =>
  (obj: T): obj is T & { [P in K]-?: NonNullable<T[P]> } =>
    obj[propName] !== null && obj[propName] !== undefined

const _get = (obj: any, path: Array<string>) => reduce(path, (obj, segment) => obj[segment] ?? {}, obj)

export function get(obj: any, path: Array<string>): any

export function get(path: Array<string>): (obj: any) => any

export function get() {
  return purry(_get, arguments)
}
