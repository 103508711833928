import styled, { css } from 'styled-components'

import MaskedInput from 'core/components/MaskedInput'
import { variables } from 'core/styles'

const sharedStyles = css`
  color: ${variables.colorBlack60};
  font-size: 16px;
`
const Amount = styled.span`
  display: block;
  ${sharedStyles}
`
const CustomMaskedInput = styled(MaskedInput)`
  display: inline-block;
  border: none;
  border-bottom: 1px solid ${variables.colorBlack30};
  border-radius: 0;
  ${sharedStyles}
`
const Container = styled.div`
  &:not(:first-child) {
    margin-top: 24px;
  }
  & > .amount-input-wrapper,
  & > ${Amount} {
    margin-left: 32px;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }
`
const RadioLabel = styled.label<{ width?: string }>`
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  ${(p) => (p.width ? `width: ${p.width};` : '')}
  cursor: pointer;
  padding-left: 32px;
  line-height: 20px;
  color: ${variables.colorBlack90};
  font-size: 16px;

  &::before {
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    border-radius: 100%;
    padding: 0;
    width: 20px;
    height: 20px;
    content: '';
  }

  &::after {
    box-sizing: border-box;
    position: absolute;
    top: calc(50% - 3.5px);
    left: 7px;
    transition: all 0.2s ease;
    border: none;
    border-radius: 100%;
    background-color: ${variables.colorWhite};
    padding: 0;
    width: 6px;
    height: 6px;
    content: '';
  }
`
const RadioInput = styled.input.attrs({ type: 'radio' })`
  display: none;

  &:disabled + ${RadioLabel} {
    cursor: not-allowed;
  }

  &:checked + ${RadioLabel}::before {
    border: 2px solid ${(p) => p.theme.primary};
    background-color: ${(p) => p.theme.secondary};
  }
  &:not(:checked) + ${RadioLabel}::before {
    border: 2px solid ${variables.colorBlack70};
    background-color: ${variables.colorWhite};
  }
  &:disabled + ${RadioLabel}::before {
    border: 2px solid ${variables.colorBlack20};
    background-color: ${variables.colorBlack20};
  }

  &:checked + ${RadioLabel}::after {
    transform: scale(1);
    opacity: 1;
  }
  &:not(:checked) + ${RadioLabel}::after {
    transform: scale(0);
    opacity: 0;
  }
`

const SubLabel = styled.span`
  display: block;
  margin-left: 32px;
  color: ${variables.colorBlack50};
  font-size: 12px;
  font-weight: 500;
`

export { Amount, CustomMaskedInput, Container, RadioInput, RadioLabel, SubLabel }
