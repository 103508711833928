import { FC, ReactNode } from 'react'

import styled from 'styled-components'

import variables from 'core/styles/variables'

/** This component is how we want to style most form labels
 * and input field labels. The prop className makes this
 * component styleable, so you can use it like this, for
 * example (using styled-components):
 *
 * const StyledFormLabel = styled(FormLabel)`
 *   margin-right: 24px;
 *   width: 240px;
 * `
 *
 * Please use the styleable aspect responsibly. Do not
 * change css properties that are already defined in
 * FormLabel itself, like font-size and color. If you
 * feel you need to change any of these, then either you
 * need to just make an entirely new component, or the design
 * team needs to approve a consistent change to FormLabels
 * across the app.
 */
type FormLabelProps = {
  text: ReactNode
  htmlFor?: string
  className?: string
}

const FormLabel: FC<FormLabelProps> = ({ text, htmlFor, className }) => (
  <Label as={htmlFor ? 'label' : 'span'} data-testid='label' className={className} htmlFor={htmlFor}>
    {text}
  </Label>
)

export default FormLabel

export const Label = styled.span`
  text-transform: uppercase;
  letter-spacing: 0.5px;
  color: ${variables.colorBlack60};
  font-size: 12px;
  font-weight: 500;
`
