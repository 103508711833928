import qs from 'qs'

import { APIendpoint } from 'core/actions/constants'
import { rawApiCall } from 'core/api'
import { getOverride } from 'core/components/PeachOverrides/storage'

import { store, retrieve } from 'admin/hooks/useStoredState/storage'

const key = 'LOCAL_BOOSTRAP_PARAMS'

const getUrlParams = () => {
  try {
    return qs.parse(window.location.search.substring(1)) || {}
  } catch {
    return {}
  }
}

const getLocalStorageParams = () => retrieve(key) || {}

const setLocalStorageParams = (params) => {
  store(key, params)
  return params
}

const getBootstrapParams = () => {
  if (!import.meta.env.VITE_CUSTOM_BOOTSTRAP_ENABLED) {
    return { domain: window.location.hostname }
  }

  const { domain: urlDomain, companyId: urlCompanyId, appDomainType: urlAppDomainType } = getUrlParams()

  if (urlDomain) {
    return setLocalStorageParams({
      domain: urlDomain,
    })
  }

  if (urlCompanyId && urlAppDomainType) {
    return setLocalStorageParams({
      companyId: urlCompanyId,
      appDomainType: urlAppDomainType,
    })
  }

  const { domain: localDomain, companyId: localCompanyId, appDomainType: localAppDomainType } = getLocalStorageParams()
  const overrideDomain = getOverride('domain')
  const overrideCompanyId = getOverride('companyId')
  const overrideAppDomainType = getOverride('appDomainType')

  const customDomain = overrideDomain || localDomain
  const customCompanyId = overrideCompanyId || localCompanyId
  const customAppDomainType = overrideAppDomainType || localAppDomainType

  if (customDomain) {
    return { domain: customDomain }
  }

  if (customCompanyId && customAppDomainType) {
    return { companyId: customCompanyId, appDomainType: customAppDomainType }
  }

  if (import.meta.env.VITE_CUSTOM_DOMAIN) {
    return { domain: import.meta.env.VITE_CUSTOM_DOMAIN }
  }

  if (import.meta.env.VITE_CUSTOM_COMPANY_ID && import.meta.env.VITE_CUSTOM_APP_DOMAIN_TYPE) {
    return {
      companyId: import.meta.env.VITE_CUSTOM_COMPANY_ID,
      appDomainType: import.meta.env.VITE_CUSTOM_APP_DOMAIN_TYPE,
    }
  }

  return { domain: window.location.hostname }
}

const fetchBootstrapData = async () => {
  return await rawApiCall({
    method: 'GET',
    url: `${APIendpoint}/bootstrap`,
    params: getBootstrapParams(),
  })
}

export default fetchBootstrapData
