import React, { createContext, useContext } from 'react'

import PropTypes from 'prop-types'

const createStateContext = (name = 'Unknown', defaultValue) => {
  const ValueContext = createContext(defaultValue)
  const OnChangeContext = createContext(() => {})

  ValueContext.displayName = `${name}ValueContext`

  OnChangeContext.displayName = `${name}OnChangeContext`

  const Provider = ({ value, onChange, children }) => {
    return (
      <ValueContext.Provider value={value}>
        <OnChangeContext.Provider value={onChange}>{children}</OnChangeContext.Provider>
      </ValueContext.Provider>
    )
  }

  Provider.displayName = `${name}Provider`

  Provider.propTypes = {
    value: PropTypes.any,
    onChange: PropTypes.any,
    children: PropTypes.node,
  }

  const useContextState = () => {
    const value = useContext(ValueContext)
    const onChange = useContext(OnChangeContext)
    return [value, onChange]
  }

  const useContextValue = () => useContext(ValueContext)

  return [Provider, useContextState, useContextValue]
}

export default createStateContext
