import { FC } from 'react'

import styled from 'styled-components'

import Icon from 'core/components/Icon'
import { R } from 'core/helpers'
import { variables } from 'core/styles'

export const truncatePageNumbers = ({
  currentPage,
  totalPages,
  maxPagesVisible,
  numPagesOnEnds,
}: {
  currentPage: number
  totalPages: number
  maxPagesVisible: number
  numPagesOnEnds: number
}) => {
  // No truncation required: return an array of sequential numbers
  if (totalPages <= maxPagesVisible) return R.range(0, totalPages)

  // Start with an array of unique pages that are always visible
  // * currentPage
  // * numPagesAtEnds range at start (eg. 1, 2, 3)
  // * numPagesAtEnds range at end (eg. 9, 10, 11)
  let pages = R.uniq([
    currentPage,
    ...R.range(0, numPagesOnEnds),
    ...R.range(Math.max(totalPages - numPagesOnEnds, 0), totalPages),
  ])

  // Fill the array evenly until length === maxPagesVisible
  let i = 0
  while (pages.length !== maxPagesVisible) {
    // Increase or flip sign of offset
    i = i < 0 ? i * -1 : (i + 1) * -1

    const page = currentPage + i

    // Skip page if it is in the starting or ending sections
    if (page < numPagesOnEnds || page >= totalPages - numPagesOnEnds) continue

    pages.push(page)
  }

  pages.sort((a, b) => a - b)

  // Mark starting truncation point
  if (pages[numPagesOnEnds] - pages[numPagesOnEnds - 1] > 1) {
    pages[numPagesOnEnds] = -1
  }

  // Mark ending truncation point
  if (pages[pages.length - numPagesOnEnds] - pages[pages.length - numPagesOnEnds - 1] > 1) {
    pages[pages.length - numPagesOnEnds - 1] = -1
  }

  return pages
}

type PaginationProps = {
  limit: number
  offset: number
  total: number | undefined
  maxPagesVisible?: number
  numPagesOnEnds?: number
  onChange: (offset: number) => void
}

const Pagination: FC<PaginationProps> = ({
  limit,
  offset,
  total = 0,
  maxPagesVisible = 20,
  numPagesOnEnds = 3,
  onChange,
}) => {
  const currentPage = Math.ceil(offset / limit)
  const totalPages = Math.ceil(total / limit)

  return totalPages < 2 ? null : (
      <PageNav>
        <PageButton onClick={() => onChange((currentPage - 1) * limit)} disabled={currentPage === 0}>
          <Icon name='arrow_back_ios' />
        </PageButton>
        {R.map.indexed(
          truncatePageNumbers({ currentPage, totalPages, maxPagesVisible, numPagesOnEnds }),
          (page, index) =>
            page === -1 ?
              <PageButton key={`page-${index}`} active={page === currentPage} disabled>
                ...
              </PageButton>
            : <PageButton key={`page-${index}`} active={page === currentPage} onClick={() => onChange(page * limit)}>
                {page + 1}
              </PageButton>,
        )}
        <PageButton onClick={() => onChange((currentPage + 1) * limit)} disabled={currentPage === totalPages - 1}>
          <Icon name='arrow_forward_ios' />
        </PageButton>
      </PageNav>
    )
}

export default Pagination

const PageButton = styled.button<{ active?: boolean }>`
  display: inline-grid;
  position: relative;
  align-items: center;
  justify-content: center;
  border: none;
  background: ${variables.colorWhite};
  cursor: pointer;
  width: 40px;

  &:disabled {
    cursor: default;
    color: ${variables.colorBlack50};
  }

  &:not(:disabled) {
    &:hover {
      background: ${variables.colorBlack10};
    }
  }

  ${(p) =>
    p.active &&
    `
      font-weight: 900;
      color: ${variables.colorBluePrimary};
      background: ${variables.colorBlack10};

      &::after {
        position: absolute;
        inset: -1px;
        z-index: 1;
        content: '';
        border: 1px solid ${variables.colorBluePrimary};
      }
    `}
`

export const PageNav = styled.nav`
  display: inline-flex;
  border: 1px solid ${variables.colorBlack20};
  border-radius: 8px;
  height: 40px;

  ${PageButton} {
    &:first-of-type {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &:last-of-type {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
    & + ${PageButton} {
      border-left: 1px solid ${variables.colorBlack20};
    }
  }
`
