import * as changeCase from 'change-case'

import { R } from 'core/helpers'

export * from 'change-case'

export { default as pluralize } from 'pluralize'

const _sentenceCase = (input: string) => changeCase.sentenceCase(input)

export function sentenceCase(input: string): string

export function sentenceCase(): (input: string) => string

export function sentenceCase() {
  return R.purry(_sentenceCase, arguments)
}

const _dropStart = (haystack: string, needle: string) =>
  haystack.startsWith(needle) ? haystack.substring(needle.length) : haystack

export function dropStart(haystack: string, needle: string): string

export function dropStart(needle: string): (haystack: string) => string

export function dropStart() {
  return R.purry(_dropStart, arguments)
}

const _dropEnd = (haystack: string, needle: string) =>
  haystack.endsWith(needle) ? haystack.substring(0, haystack.length - needle.length) : haystack

export function dropEnd(haystack: string, needle: string): string

export function dropEnd(needle: string): (haystack: string) => string

export function dropEnd() {
  return R.purry(_dropEnd, arguments)
}

const _capitalize = (string: string) => string.charAt(0).toUpperCase() + string.substring(1)

export function capitalize(string: string): string

export function capitalize(): (haystack: string) => string

export function capitalize() {
  return R.purry(_capitalize, arguments)
}

export const join = (items: Array<string | null | undefined>, glue = ' ') => R.pipe(items, R.compact, R.join(glue))
