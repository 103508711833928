import { ReactNode } from 'react'

import { FieldPath, FieldValues, useController, UseControllerProps } from 'react-hook-form'
import { GroupBase, OnChangeValue, Props as ReactSelectProps } from 'react-select'

import { PendingEventHandler } from 'core/components/lib/Button/Button'

import { SelectOption } from './components'
import Select from './Select'

type SelectInputProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  IsMulti extends boolean,
  Value,
> = Omit<UseControllerProps<TFieldValues, TName>, 'disabled'> &
  Omit<
    ReactSelectProps<SelectOption<Value>, IsMulti, GroupBase<SelectOption<Value>>>,
    'disabled' | 'onChange' | 'onBlur' | 'value' | 'name' | 'styles' | 'defaultValue'
  > & {
    className?: string
    width?: string
    onChange?: PendingEventHandler<OnChangeValue<Value, IsMulti> | null>
    prefix?: ReactNode
  }

/** @deprecated Avoid react-form-hooks and use Select instead */
const SelectInput = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  IsMulti extends boolean = false,
  Value = string,
>({
  control,
  defaultValue,
  name,
  rules,
  shouldUnregister,
  isDisabled,
  onChange: passedOnChange,
  ...props
}: SelectInputProps<TFieldValues, TName, IsMulti, Value>) => {
  const {
    formState: { isSubmitting },
    field: { onChange: controlOnChange, disabled, ...field },
  } = useController({
    control,
    defaultValue,
    name,
    rules,
    shouldUnregister,
    disabled: isDisabled,
  })

  return (
    <Select
      onChange={(value) => {
        controlOnChange(value)
        passedOnChange?.(value)
      }}
      {...field}
      {...props}
      disabled={disabled || isSubmitting}
    />
  )
}

export default SelectInput
