import { PropsWithChildren } from 'react'

import styled from 'styled-components'

import Spinner from '../Spinner'

const Overlay = styled.span<{ shaded?: boolean }>`
  display: flex;
  position: absolute;

  /* Offset from top and bottom for compatibility with bordered wrappers */
  inset: 4px 0;
  justify-content: center;
  padding-top: 57px;
  ${(p) => p.shaded && `background: rgba(255, 255, 255, 0.85);`}
`

const OverlayBody = styled(Overlay)`
  top: 41px;
  align-items: center;
  padding-top: 0;
`

const FlexContainer = styled.span`
  display: flex;
  align-items: bottom;
  font-size: 20px;
`

export function TableLoadingOverlay() {
  return (
    <Overlay shaded>
      <FlexContainer>
        <Spinner height='28px' position='relative' />
      </FlexContainer>
    </Overlay>
  )
}

export function TableTextOverlay({ children }: PropsWithChildren<{}>) {
  return <OverlayBody>{children}</OverlayBody>
}
