import React, { createContext, useContext } from 'react'

import PropTypes from 'prop-types'

import { combine } from './storedStateHelpers'

const StoredStateNamespaceContext = createContext('')

const useStoredStateNamespace = () => useContext(StoredStateNamespaceContext)

const StoredStateNamespace = ({ namespace = 'storageNamespace', children }) => {
  const existingNamespace = useStoredStateNamespace()
  const newNamespace = combine(existingNamespace, namespace)

  return <StoredStateNamespaceContext.Provider value={newNamespace}>{children}</StoredStateNamespaceContext.Provider>
}

StoredStateNamespace.propTypes = {
  namespace: PropTypes.string,
  children: PropTypes.node,
}

export { useStoredStateNamespace, StoredStateNamespace }
