import styled from 'styled-components'

import variables from 'core/styles/variables'

/** Use this in between instances of CardSection as desired */
const Divider = styled.div<{
  marginLeft?: string
  full?: boolean
}>`
  grid-column: 1 / -1;
  margin-left: ${(p) => (p.full ? '0' : (p.marginLeft ?? '24px'))};
  border-bottom: 1px solid ${variables.colorBlack20};
`
export default Divider
